import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy';
import { AspectRatio, Types } from 'common';

const useStyles = makeStyles((theme) => ({
	player: {
		backgroundColor: theme.palette.common.black,
		margin: '0px auto'
	}
}));

export type PlayerProps = ReactPlayerProps & {
	url: string;
	startAtSeconds?: number;
	thumbnailUrl?: string;
	contentType?: Types.ContentType;
};

const Player = (props: PlayerProps): JSX.Element => {
	const classes = useStyles();
	const {
		url,
		startAtSeconds,
		thumbnailUrl,
		volume = 1,
		width = '100%',
		height = '100%',
		controls = true,
		playsinline = true,
		playing = true,
		loop = false,
		isPdf,
		className = classes.player,
		...others
	} = props;

	const handleOnReady = (player: ReactPlayer) => {
		if (startAtSeconds != null) {
			player.seekTo(startAtSeconds, 'seconds');
		}
	};

	return (
		<AspectRatio>
			<ReactPlayer
				volume={volume}
				url={url}
				playsinline={playsinline}
				playing={playing}
				controls={controls}
				loop={loop}
				width={width}
				height={height}
				className={className}
				light={thumbnailUrl}
				onReady={handleOnReady}
				data-testid="video-player"
				id="video-player"
				{...others}
			/>
		</AspectRatio>
	);
};

export default Player;
