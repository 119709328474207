import React, { useState } from 'react';
import { Box, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Dialog, Typography } from 'components';
import { useIsDesktop } from 'common';
import { useIntl } from 'react-intl';
import Player from '../../../components/Player/components/Player';

const useStyles = makeStyles((theme) => ({
	membershipTeaser: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	playerContainer: {
		padding: theme.spacing(3, 0, 0, 0),
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(3)
		}
	}
}));

const mediathekVideoByLocale: Record<Locale, string> = {
	'de-CH': 'https://vimeo.com/1008016984?share=copy',
	'de-DE': 'https://vimeo.com/1008016331?share=copy',
	'fr-CH': 'https://vimeo.com/1008016363?share=copy',
	'de-AT': 'https://vimeo.com/1008016313?share=copy',
	'en-GB': ''
};

export const MembershipTeaser = ({
	shouldShowPlayerDialog,
	textClassName
}: {
	shouldShowPlayerDialog: boolean;
	textClassName?: string;
}) => {
	const classes = useStyles();
	const isDesktop = useIsDesktop();
	const { locale } = useIntl();
	const membershipVideo = mediathekVideoByLocale[locale] || '';
	const [isOpenMediathekVideo, setIsOpenMediathekVideo] = useState(false);
	const handlePlayMembershipClick = () => setIsOpenMediathekVideo(true);
	const handleCloseMembershipVideo = () => setIsOpenMediathekVideo(false);

	return (
		<>
			{shouldShowPlayerDialog && Boolean(membershipVideo) && (
				<Dialog
					open={isOpenMediathekVideo}
					onClose={handleCloseMembershipVideo}
					maxWidth={isDesktop ? 'lg' : 'md'}
					fullWidth
				>
					<DialogContent>
						<Box className={classes.playerContainer}>
							<Player url={membershipVideo} />
						</Box>
					</DialogContent>
				</Dialog>
			)}
			<Box className={classes.membershipTeaser} onClick={handlePlayMembershipClick}>
				<img src="https://storage.googleapis.com/bk-fms-eu/public/static/icon-play-button-circled.svg" />
				<Typography
					className={textClassName || ''}
					localeId="catalog.home.group-membership-banner.more-about-membership"
				/>
			</Box>
		</>
	);
};
